@charset "UTF-8";
/*Import font-awesome*/
@import url(~font-awesome/css/font-awesome.min.css);
/*Import Core*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: flex; }

body,
html {
  width: 100%;
  height: 100%; }

body {
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: none;
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif; }

ol,
ul {
  list-style: none; }

hr {
  border: 0;
  height: 1px;
  background: #ccc; }

blockquote,
q {
  quotes: none; }

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  margin: 0;
  padding: 0;
  list-style: none;
  box-sizing: border-box; }

html,
input,
select,
textarea,
button,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Black.eot");
  src: url("font/hinted-AvenirLTStd-Black.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Black.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Black.woff") format("woff"), url("font/hinted-AvenirLTStd-Black.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Black.svg#AvenirLTStd-Black") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Heavy.eot");
  src: url("font/hinted-AvenirLTStd-Heavy.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Heavy.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Heavy.woff") format("woff"), url("font/hinted-AvenirLTStd-Heavy.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Heavy.svg#AvenirLTStd-Heavy") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Book.eot");
  src: url("font/hinted-AvenirLTStd-Book.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Book.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Book.woff") format("woff"), url("font/hinted-AvenirLTStd-Book.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Book.svg#AvenirLTStd-Book") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Roman.eot");
  src: url("font/hinted-AvenirLTStd-Roman.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Roman.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Roman.woff") format("woff"), url("font/hinted-AvenirLTStd-Roman.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Roman.svg#AvenirLTStd-Roman") format("svg");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Light.eot");
  src: url("font/hinted-AvenirLTStd-Light.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Light.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Light.woff") format("woff"), url("font/hinted-AvenirLTStd-Light.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Light.svg#AvenirLTStd-Light") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'Avenir LT Std';
  src: url("font/hinted-AvenirLTStd-Medium.eot");
  src: url("font/hinted-AvenirLTStd-Medium.eot?#iefix") format("embedded-opentype"), url("font/hinted-AvenirLTStd-Medium.woff2") format("woff2"), url("font/hinted-AvenirLTStd-Medium.woff") format("woff"), url("font/hinted-AvenirLTStd-Medium.ttf") format("truetype"), url("font/hinted-AvenirLTStd-Medium.svg#AvenirLTStd-Medium") format("svg");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("font/fa-regular-400.eot?v=5.6.3");
  src: url("font/fa-regular-400.eot?#iefix&v=5.6.3") format("embedded-opentype"), url("font/fa-regular-400.woff2?v=5.6.3") format("woff2"), url("font/fa-regular-400.woff?v=5.6.3") format("woff"), url("font/fa-regular-400.ttf?v=5.6.3") format("truetype"), url("font/fa-regular-400.svg?v=5.6.3#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("font/fa-solid-900.eot?v=5.6.3");
  src: url("font/fa-solid-900.eot?#iefix&v=5.6.3") format("embedded-opentype"), url("font/fa-solid-900.woff2?v=5.6.3") format("woff2"), url("font/fa-solid-900.woff?v=5.6.3") format("woff"), url("font/fa-solid-900.ttf?v=5.6.3") format("truetype"), url("font/fa-solid-900.svg?v=5.6.3#fontawesomeregular") format("svg");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'FontAwesome';
  src: url("font/fa-brands-400.eot?v=5.6.3");
  src: url("font/fa-brands-400.eot?#iefix&v=5.6.3") format("embedded-opentype"), url("font/fa-brands-400.woff2?v=5.6.3") format("woff2"), url("font/fa-brands-400.woff?v=5.6.3") format("woff"), url("font/fa-brands-400.ttf?v=5.6.3") format("truetype"), url("font/fa-brands-400.svg?v=5.6.3#fontawesomeregular") format("svg");
  font-weight: 400;
  font-style: normal; }

* {
  font-family: 'Avenir LT Std', 'Helvetica Nueue', sans-serif, 'FontAwesome'; }

html {
  font-size: 62.5%; }

body {
  background-color: #FFFFFA; }

.container {
  background-color: #FFFFFA;
  width: 100%;
  height: 100%;
  overflow-y: scroll; }

.notifications-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px; }
  .notifications-list .notification-card {
    width: 90%;
    padding: 30px;
    color: #094063;
    margin-bottom: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    text-decoration: none; }
    .notifications-list .notification-card:hover {
      transform: scale(1.05);
      z-index: 2; }
  .notifications-list .notification-title {
    font-size: 1.6rem;
    font-weight: 600; }
  .notifications-list .notification-content {
    font-size: 1.6rem;
    font-weight: 300; }

.notification-detail-card {
  margin: 0 auto;
  margin-top: 40px;
  border-radius: 4px;
  background-color: #f9f9f9;
  width: 90%; }
  .notification-detail-card .notification-detail {
    padding: 30px;
    color: #094063;
    font-size: 1.6rem;
    font-weight: 600; }
  .notification-detail-card .notification-detail > h2, .notification-detail-card p {
    margin-bottom: 1em; }
  .notification-detail-card h2 {
    color: #212121; }
  .notification-detail-card p {
    color: #4B4B4B; }
  .notification-detail-card a {
    background-color: #094063;
    color: white;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 600;
    padding: 10px 40px;
    text-decoration: none; }

.text_left {
  text-align: left; }

.text_right {
  text-align: right; }

.no-margin {
  margin: 0 !important; }

.no-padding {
  padding: 0 !important; }

.button-header {
  display: flex;
  justify-content: flex-end;
  padding: 1rem; }

.primary_button {
  border-radius: 4px;
  font-size: 1.4rem;
  font-style: normal;
  background-color: #094063;
  color: #FFFFFA;
  margin: 1rem;
  padding: 7px 15px;
  border: 0;
  cursor: pointer; }
  .primary_button a {
    color: #FFFFFA;
    text-decoration: none; }

.btn {
  border-radius: 4px;
  font-size: 1.4rem;
  font-style: normal;
  margin: 1rem;
  padding: 7px 15px;
  border: 0;
  cursor: pointer; }

.btn.primary {
  background-color: #094063;
  color: #FFFFFA; }
  .btn.primary a {
    color: #FFFFFA;
    text-decoration: none; }

.btn.transparent {
  background: transparent;
  color: #094063;
  border: 1px solid #094063; }

.btn.transparent:hover {
  background: #094063;
  color: #FFFFFA;
  border: 1px solid #094063; }

.btn.link {
  background-color: transparent;
  color: #094063; }

.primary_button.no-border {
  background-color: transparent;
  color: #094063; }

.link_button {
  text-decoration: underline;
  color: #107BC0;
  background: none;
  border: none;
  font-size: 1.4rem; }

button:disabled {
  cursor: not-allowed;
  background: darkgrey !important; }

input:read-only {
  border-color: darkgrey !important;
  cursor: not-allowed; }

.red {
  background-color: #b32727 !important; }

.green {
  background-color: #058605 !important; }

.col-flex {
  display: flex;
  flex-direction: column; }

.row-flex {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; }

._deactivated {
  background-color: #d0cdcd !important;
  color: #585757 !important; }

.welcome-wrapper {
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 100%;
  text-align: center; }
  .welcome-wrapper .logo {
    max-width: 150px; }
  .welcome-wrapper h1 {
    font-size: 8rem;
    font-weight: lighter;
    margin: 2rem; }
  .welcome-wrapper h4 {
    font-size: 1.8rem;
    margin-bottom: 5rem;
    font-weight: lighter; }
  .welcome-wrapper ul.action-chooser li {
    margin: 1rem;
    cursor: pointer; }
    .welcome-wrapper ul.action-chooser li a {
      color: #212121;
      text-decoration: none; }
  .welcome-wrapper ul.action-chooser .action-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: 0 0 10px 0 #eee; }
    .welcome-wrapper ul.action-chooser .action-item .action-item__icon {
      display: block;
      width: 150px;
      height: 150px; }
    .welcome-wrapper ul.action-chooser .action-item .action-item__label {
      margin-top: 2rem;
      font-size: 1.3rem;
      font-weight: bolder; }

.login_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: 300;
  background-color: #ffffff; }
  .login_form .form_wrapper {
    padding: 2rem; }
    .login_form .form_wrapper .form {
      width: 100%;
      height: 100%;
      text-align: center;
      margin-bottom: 8rem; }
      .login_form .form_wrapper .form input[type=text],
      .login_form .form_wrapper .form input[type=password] {
        width: 100%;
        height: 5rem;
        padding: 1rem;
        border: 1px solid #C0E5E5;
        border-radius: 4px;
        box-sizing: border-box;
        margin-top: 2rem;
        margin-bottom: 1rem; }
      .login_form .form_wrapper .form button {
        border-radius: 4px;
        font-size: 1.6rem;
        width: 100%;
        height: 5.5rem;
        font-style: normal;
        font-weight: 500;
        background-color: #094063;
        color: #FFFFFA;
        margin-top: 2rem;
        margin-bottom: 2rem;
        border: 0;
        cursor: pointer; }

.form_logo {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  color: #094063;
  padding: 2rem; }
  .form_logo p {
    font-size: 2.5rem; }

#left {
  flex-shrink: 0; }

.top-right {
  flex-shrink: 0;
  display: inline-flex; }

.dashboard {
  display: flex;
  flex-direction: row;
  width: 100%; }

.filter-options {
  display: flex;
  justify-content: center;
  height: auto;
  padding: 1em 0;
  color: #ffffff; }
  .filter-options .input-options {
    display: flex;
    background-color: #094063;
    padding: 1em 3em;
    font-weight: bold; }
  .filter-options .input-options .filter, .filter-options .input-options p {
    margin-right: 1em; }

.dropdown-content {
  float: none;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left; }

.profile__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center; }

.profile__planinfo {
  display: flex;
  padding: 2rem;
  flex-direction: column;
  justify-content: center;
  color: #212121; }

.menu-active, .sub_menu_items.show .menu-active {
  background-color: rgba(0, 0, 0, 0.1);
  border-left: 5px solid #107BC0; }

.content {
  width: 100%;
  position: relative; }

.table-header span {
  margin-right: 10px; }

.table-caret {
  display: inline-block;
  vertical-align: middle; }
  .table-caret i {
    display: block; }

.badge {
  position: relative; }

.badge[data-badge]:after {
  content: attr(data-badge);
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: .8rem;
  background: #D72C2C;
  color: white;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  border-radius: 50%;
  font-style: normal;
  font-weight: normal; }

.fa {
  color: #094063; }

.dashboard-container {
  height: 100%;
  overflow: hidden;
  margin: 0px;
  display: flex;
  box-sizing: border-box; }

.dashboard__table {
  width: 100%;
  text-align: left; }
  .dashboard__table th {
    padding: 1.5rem 2rem;
    font-size: 1.4rem;
    border-bottom: 1px solid #e3e3e3;
    font-style: normal;
    font-weight: normal;
    color: #094063;
    height: 6rem;
    vertical-align: middle;
    text-transform: uppercase; }
  .dashboard__table tr:hover {
    background-color: #f5f5f5; }
  .dashboard__table td {
    padding: 1.5rem 2rem;
    font-size: 1.6rem;
    border-bottom: 1px solid #e3e3e3;
    font-style: normal;
    font-weight: 300;
    color: #212121;
    height: 6rem;
    vertical-align: middle; }
  .dashboard__table a {
    text-decoration: none;
    color: #107BC0;
    width: 100%;
    display: flex; }

.modal {
  display: none;
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4); }

.modal.open {
  display: flex; }

.modal-content {
  background-color: #fefefe;
  margin: auto;
  padding: 2rem;
  width: auto;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  align-items: center; }

.modal-header {
  font-size: 2rem;
  margin-bottom: 2rem;
  font-style: normal;
  font-weight: 500;
  color: #094063;
  text-transform: capitalize; }

.modal-close {
  color: #aaaaaa;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
  align-self: flex-end; }

.modal-wrapper {
  width: 100%;
  height: 100%;
  background-color: rgba(12, 12, 12, 0.85);
  position: absolute;
  top: 0;
  left: 0;
  justify-content: center;
  display: flex;
  padding: 0 3rem;
  z-index: 9999;
  overflow-y: scroll; }
  .modal-wrapper .modal {
    background: #fff;
    width: 800px;
    height: auto;
    margin: auto;
    border-radius: 3px; }
    .modal-wrapper .modal .modal-header {
      padding: 1.5rem;
      background: #f3f3f3;
      display: flex;
      justify-content: space-between; }
      .modal-wrapper .modal .modal-header .close {
        font-size: 18px;
        margin-right: 2rem;
        align-self: center;
        color: gray;
        font-weight: bolder;
        cursor: pointer; }
    .modal-wrapper .modal .modal-content {
      max-height: 480px; }
    .modal-wrapper .modal .modal-footer {
      padding: 1.5rem;
      border-top: 1px solid #cecece; }
      .modal-wrapper .modal .modal-footer button {
        color: #0a4062;
        font-weight: bolder;
        background: none;
        border: none;
        cursor: pointer; }

.mdl-layout {
  display: flex;
  flex-direction: row;
  /* box-shadow: 0px 0px 20px 0px #eee; */
  padding: 1rem;
  border-radius: 4px;
  max-width: 640px; }
  .mdl-layout .mdl-list {
    box-shadow: 0 0 20px 0 #eee;
    background: #f9f9f9;
    width: 20%; }
    .mdl-layout .mdl-list li {
      font-size: 1.2rem;
      padding: 2rem;
      cursor: pointer; }
    .mdl-layout .mdl-list li:hover {
      background-color: #ededed; }
    .mdl-layout .mdl-list li.active {
      background: white;
      font-size: 1.5rem; }
    .mdl-layout .mdl-list li.checkable {
      position: relative; }
    .mdl-layout .mdl-list li.checkable.checked:before {
      content: '';
      border-top: 3rem solid #26ae5f;
      position: absolute;
      border-left: 3rem solid transparent;
      top: 0;
      right: 0; }
    .mdl-layout .mdl-list li.checkable.checked:after {
      content: '✔';
      color: white;
      position: absolute;
      top: 0;
      right: 0;
      padding-right: 0.5rem;
      padding-top: 0.3rem; }
  .mdl-layout .mdl-details .mdl-details__header {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 1rem;
    padding: 2rem;
    border-bottom: 1px solid #eee; }
  .mdl-layout .mdl-details .mdl-details__content {
    font-size: 1.7rem;
    padding: 2rem;
    min-height: 350px; }
  .mdl-layout .mdl-details .mdl-details__footer {
    display: flex;
    /* justify-content: flex-end; */
    /* border-top: 1px solid #ccc; */
    margin-top: 1rem;
    padding: 2rem; }
    .mdl-layout .mdl-details .mdl-details__footer .link_button {
      display: none; }

.extension-selector-wrapper {
  width: 100%;
  margin-bottom: 1rem; }
  .extension-selector-wrapper .extension-count {
    font-size: 1.2rem;
    text-align: end;
    padding-right: 1rem;
    color: #084062;
    width: 100%; }

.button_link {
  color: #FFFFFA;
  text-decoration: none; }

.form-input {
  border: 0.5px solid #094063;
  border-radius: 4px;
  margin-bottom: 1rem;
  padding: 0 1.2rem;
  width: 40rem;
  max-width: 100%; }

.form-button {
  border: 0;
  border-radius: 4px;
  background-color: #094063;
  padding: 1rem;
  width: 40rem;
  max-width: 100%;
  margin-bottom: 1rem;
  color: #FFFFFA;
  margin-top: 0.5rem; }

.modal-smtext {
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  font-weight: bold; }

.dot {
  width: 8px;
  height: 8px;
  border-radius: 100%;
  border: 4px solid;
  display: flex;
  margin-left: 1.2rem; }

.dot_active {
  color: rgba(0, 128, 11, 0.6); }

.dot_inactive {
  color: rgba(255, 0, 0, 0.6); }

.dot_pending {
  color: rgba(255, 116, 8, 0.6); }

.full_profile {
  display: flex;
  border-radius: 4px;
  margin: 2rem;
  justify-content: space-between;
  flex-direction: column; }
  .full_profile img {
    max-width: 28rem;
    width: 100%; }

.l-full__info {
  font-size: 1.4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 2rem; }

.l-full__info-label {
  letter-spacing: 0.3rem;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-weight: 300;
  text-transform: uppercase; }

.l-full__info-smtext {
  font-size: 1.6rem;
  padding-bottom: 2rem; }

.payment__details {
  display: flex;
  padding: 0 2rem; }

.capitalize {
  text-transform: capitalize; }

.invoice-expanded__headertext {
  font-size: 2rem;
  color: #094063;
  padding: 2rem;
  padding-bottom: 1.2rem;
  text-transform: capitalize; }

.grey_bg {
  background-color: rgba(187, 187, 204, 0.1); }

.dash__tab {
  display: flex;
  margin: 2rem;
  flex-wrap: wrap;
  width: 100%; }
  .dash__tab button {
    border: none;
    background-color: #fffffa;
    border-bottom: 4px solid rgba(9, 64, 99, 0.1);
    cursor: pointer; }
  .dash__tab button.activeTab {
    border-bottom: 4px solid #094063;
    color: #094063; }
  .dash__tab button:focus {
    outline: 0.1px solid rgba(9, 64, 99, 0.1); }

.tabDashboard {
  display: none;
  flex-direction: column; }

.success-text {
  color: #27AE60 !important;
  font-weight: bold !important; }

.danger-text {
  color: #D72C2C !important;
  font-weight: bold !important; }

.sort_button {
  position: relative; }

.sort_button-content {
  display: none;
  background-color: #f9f9f9;
  min-width: 88%;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
  position: absolute;
  left: 10px;
  border-radius: 4px; }
  .sort_button-content a {
    color: #212121;
    padding: 1rem 1.2rem;
    text-decoration: none;
    display: flex;
    font-size: 1.2rem;
    flex-direction: column; }
  .sort_button-content a:hover {
    background-color: rgba(0, 0, 0, 0.1); }

.showDropdown {
  display: flex;
  flex-direction: column; }

.profile__label {
  letter-spacing: 0.3rem;
  font-size: 1.2rem;
  padding-bottom: 1rem;
  font-weight: 300;
  text-transform: uppercase; }

.profile__infotext {
  font-size: 2.0rem;
  padding-bottom: 2rem; }

.company-info {
  display: flex;
  padding: 2rem;
  justify-content: space-between;
  align-items: center;
  flex-flow: wrap; }
  .company-info button {
    align-self: flex-end; }
  .company-info img {
    border: 1px solid rgba(0, 0, 0, 0.1);
    margin-bottom: 1rem;
    width: 25rem;
    height: 25rem; }
  .company-info .sort-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: flex-end;
    padding: 0 5rem;
    align-items: center; }
  .company-info .usage {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: wrap-reverse;
    overflow: hidden;
    position: relative; }
    .company-info .usage .usage-summary {
      width: 20%;
      display: flex;
      justify-content: space-between;
      flex-direction: column; }
    .company-info .usage .usage-graph {
      width: 75%; }

.button-grey {
  background-color: rgba(0, 0, 0, 0.1);
  border: 0;
  padding: 1rem;
  border-radius: 4px;
  font-size: 1.6rem;
  color: #212121;
  cursor: pointer;
  margin-bottom: 1rem; }

.l_card-holder {
  display: flex;
  width: 100%;
  height: auto;
  padding: 2rem;
  justify-content: flex-start;
  flex-wrap: wrap; }

.l_card {
  min-width: 18rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  height: 10rem;
  padding: 0.5rem;
  margin: 1rem; }

.big_num {
  color: #107bc0;
  font-size: 3.6rem;
  font-style: normal;
  font-weight: 500; }

.smalltext {
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase; }

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
  vertical-align: middle; }
  .switch input {
    display: none; }

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s; }

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s; }

.addEmployee {
  display: flex;
  flex-direction: column;
  padding: 2rem; }
  .addEmployee h2 {
    font-size: 2rem;
    color: #094063;
    line-height: 2.6rem; }
  .addEmployee p {
    font-size: 1.6rem;
    color: #212121;
    line-height: 2rem; }

.spreadSheet {
  margin: 1rem;
  width: 50%;
  height: 40rem;
  position: relative; }
  .spreadSheet h4 {
    font-size: 1.4rem;
    line-height: 2rem; }

.addEmpForm {
  margin-right: 1rem;
  width: 50%; }

.l-addEmployee {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem; }

.addEmployee__invsum {
  border-top: 0.5px solid rgba(0, 0, 0, 0.1);
  padding-top: 2rem;
  margin-top: 2rem; }

.l-form {
  display: flex;
  flex-direction: column; }
  .l-form span {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  .l-form .select {
    border: 0.5px solid #094063;
    width: 30rem;
    max-width: 100%;
    border-radius: 4px;
    padding-top: 11px;
    margin-bottom: 1rem; }
    .l-form .select select {
      border: none;
      background-color: transparent; }
    .l-form .select select:focus {
      outline: none; }

input:checked + .slider {
  background-color: #107BC0; }

input:focus + .slider {
  box-shadow: 0 0 1px #107BC0; }

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px); }

/* Rounded sliders */
.slider.round {
  border-radius: 20px; }

.slider.round:before {
  border-radius: 50%; }

.dash__settings {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  height: 100%;
  justify-content: space-evenly;
  font-size: 1.4rem;
  color: #212121; }
  .dash__settings span {
    display: flex;
    width: 100%;
    justify-content: space-between; }

.alert__banner {
  padding: 2rem 3rem;
  color: white; }
  .alert__banner h3 {
    font-size: 1.7rem;
    margin-bottom: 0.8rem; }
  .alert__banner p {
    font-size: 1.2rem; }

.alert__banner.success {
  background: #058605; }

.alert__banner.error {
  background: #b32727; }

.alert__banner.warn {
  background: #ff6700; }

.signup {
  display: flex;
  flex-direction: column;
  align-items: center; }

.signup__header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 6rem;
  padding-bottom: 3rem;
  border-bottom: 1px solid rgba(9, 64, 99, 0.1);
  width: 100%; }
  .signup__header img {
    height: 4rem; }
  .signup__header p {
    font-size: 2.2rem;
    color: #212121;
    font-style: normal;
    font-weight: normal; }

.form_group {
  display: inline-flex; }

.form hr {
  margin: 10px 0; }

.form select,
.form option,
.form input[type=text],
.form input[type=email],
.form input[type=password],
.form input[type=date],
.form input[type=phone],
.form input[type=number],
.form input[type=tel] {
  width: 100%;
  height: 4rem;
  padding: 0.5rem;
  border: 1px solid #C0E5E5;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 1rem;
  margin-bottom: 1rem; }

.form select {
  padding: 2px; }

.form input[type=checkbox],
.form input[type=radio] {
  vertical-align: middle;
  height: 1.9rem;
  width: 1.0rem;
  margin: 1rem 1rem 1rem 0; }

.form input[type=submit] {
  background-color: #094063;
  border: none;
  color: white;
  padding: 1rem 3.2rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  margin-right: 1rem; }

.form input[type=button] {
  background-color: #FFFFFA;
  border: 1px solid #094063;
  color: #212121;
  padding: 1rem 3.2rem;
  text-align: center;
  text-decoration: none;
  font-size: 1.4rem;
  border-radius: 4px;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer; }

.form input[type=file] {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.form .form-group {
  margin: 10px 0; }

.form .hint {
  font-size: 10px;
  color: #aaa;
  font-style: italic; }

.form .radio {
  padding-left: 24px;
  margin: 10px 0; }
  .form .radio input[type=radio] {
    margin-left: -24px;
    display: inline-block;
    vertical-align: middle; }
  .form .radio label {
    display: inline-block;
    vertical-align: middle; }

.form .input-error {
  color: darkred;
  margin-bottom: 10px;
  font-weight: bold; }

.form .input-group {
  position: relative;
  display: flex; }
  .form .input-group .addon {
    font-size: 1rem;
    align-items: center;
    justify-content: center;
    padding: 0.5rem;
    margin: 1rem 0;
    background: #eeeeee;
    width: 20%;
    display: flex;
    border: 1px solid #C0E5E5;
    color: #6b6969; }
  .form .input-group input {
    width: 80%; }

.form .input-group.right input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.form .input-group.right .addon {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px; }

.form .input-group.left input {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.form .input-group.left .addon {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px; }

.form .horizontal {
  display: flex;
  justify-content: space-around; }
  .form .horizontal .col {
    flex-direction: column;
    display: flex;
    padding: 1rem; }

.form-horizontal {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap; }
  .form-horizontal .col {
    flex-direction: column;
    display: flex;
    padding: 1rem; }

.planner__form {
  max-width: 100%;
  width: 100%;
  display: flex;
  padding: 30px 20px; }
  .planner__form h1 {
    font-size: 32px; }
  .planner__form .intro {
    font-size: 14px;
    margin: 10px 0; }
  .planner__form h1 {
    font-size: 32px; }

.signup__form {
  max-width: 80%;
  width: 40rem;
  padding-top: 3rem;
  padding-bottom: 3rem;
  font-size: 1.4rem;
  color: #212121; }

.plan-selection-wrapper {
  display: flex;
  flex-direction: column;
  padding-bottom: 10rem; }

.plan-selection__header-wrapper {
  margin-top: 60px; }
  .plan-selection__header-wrapper h2 {
    text-align: center;
    font-size: 2.2rem;
    color: #094063;
    font-weight: 300;
    font-style: normal;
    line-height: 3rem;
    padding: 4rem; }

.plan-selection__duration-wrapper {
  display: flex;
  border: 1px solid #107bc0;
  border-radius: 4px;
  align-self: center; }
  .plan-selection__duration-wrapper .plan_duration_btn {
    padding: 2rem;
    font-size: 1.6rem;
    border: 0;
    background-color: #f2f2f2;
    height: 6rem;
    width: 15rem;
    cursor: pointer; }
  .plan-selection__duration-wrapper .plan_duration_btn_active {
    font-size: 1.8rem;
    background-color: #107bc0;
    color: white; }
  .plan-selection__duration-wrapper .lpdbc {
    border-bottom-left-radius: 4px;
    border-top-left-radius: 4px; }
  .plan-selection__duration-wrapper .rpdbc {
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px; }

.plan-selection__plans-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 3rem;
  justify-content: center; }
  .plan-selection__plans-wrapper .plan_item {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 4px;
    box-shadow: 2px 2px 20px #eee;
    max-width: 50rem;
    margin: 1rem; }
    .plan-selection__plans-wrapper .plan_item .plan_name {
      font-size: 2rem;
      color: #212121; }
    .plan-selection__plans-wrapper .plan_item .plan_small_desc {
      font-size: 1.4rem;
      color: #4f4f4f;
      margin: 1rem 0; }
    .plan-selection__plans-wrapper .plan_item .plan_small_link {
      color: #107bc0;
      font-size: 1.2rem; }
    .plan-selection__plans-wrapper .plan_item .plan_name_text {
      font-size: 1.6rem;
      color: #333333;
      font-style: normal;
      text-transform: capitalize;
      font-weight: 500; }
    .plan-selection__plans-wrapper .plan_item .price {
      display: flex;
      padding: 2rem 0;
      flex-wrap: wrap; }
      .plan-selection__plans-wrapper .plan_item .price .plan_pricetag {
        font-size: 2.4rem;
        color: #27ae60; }
      .plan-selection__plans-wrapper .plan_item .price .pricetag_duration {
        font-size: 1.4rem;
        color: #212121; }
    .plan-selection__plans-wrapper .plan_item .plan_qty_select {
      margin: 1rem 0;
      font-size: 1.6rem;
      height: 4rem;
      padding: 1rem;
      border: 1px solid #5199d3;
      background-color: #fffffa;
      border-radius: 4px;
      color: #212121;
      width: 100%; }
    .plan-selection__plans-wrapper .plan_item .plan_select_btn {
      border-radius: 4px;
      padding: 1rem;
      font-size: 1.6rem;
      border: 0;
      background-color: #107bc0;
      color: white; }

.checkout-container {
  position: fixed;
  width: 80%;
  background: white;
  display: block;
  z-index: 10; }

.checkout-container.hide {
  display: none; }

.checkout-header {
  display: flex;
  width: 100%;
  border: none;
  justify-content: space-between;
  padding: 1rem 3rem;
  align-items: center;
  border-bottom: 1px solid rgba(9, 64, 99, 0.1); }

.checkout-header__plan-count {
  font-size: 1.8rem;
  width: 100%;
  cursor: pointer; }
  .checkout-header__plan-count h3 {
    display: inline-block; }
  .checkout-header__plan-count h5 {
    color: grey;
    font-size: 1rem;
    margin-left: 2rem; }

.checkout-header__button {
  margin-left: 1.5rem;
  font-weight: 500;
  font-style: normal;
  background-color: #094063;
  border-radius: 4px;
  border: 0;
  color: white;
  cursor: pointer;
  text-align: center;
  padding: 1rem;
  font-size: 1.5rem; }

.checkout-details {
  display: none; }

.checkout-details.active {
  padding: 1rem 2rem;
  width: 100%;
  border: none;
  display: block; }

.checkout-details__plan-list {
  list-style: circle; }

.checkout-details__list-item {
  font-size: 1.5rem;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .checkout-details__list-item button {
    border: none;
    color: darkred;
    background: none;
    cursor: pointer; }

.no-enrollee-wrapper {
  position: absolute;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%; }
  .no-enrollee-wrapper section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative; }
    .no-enrollee-wrapper section p {
      margin: 1rem;
      font-size: 1.5rem;
      color: #737373;
      text-align: center; }
    .no-enrollee-wrapper section button {
      padding: 1rem 2rem;
      border-radius: 4px;
      cursor: pointer;
      background-color: #FFFFFA; }
  .no-enrollee-wrapper section:nth-child(n+2) {
    border-left: 1px solid #eee; }
  .no-enrollee-wrapper section.new:after {
    background: url(https://www.materialui.co/materialIcons/action/add_shopping_cart_grey_192x192.png); }
  .no-enrollee-wrapper section.add:after {
    background: url(https://image.freepik.com/free-icon/create-account_318-31332.jpg); }
  .no-enrollee-wrapper section.add:after, .no-enrollee-wrapper section.new:after {
    content: '';
    position: absolute;
    /* background-color: red; */
    background-repeat: no-repeat;
    background-position: 100px 100px;
    background-size: 200px 200px;
    bottom: 0;
    right: 0;
    width: 300px;
    opacity: 0.08;
    height: 300px; }

.invalid-invoice {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(17, 17, 17, 0.65);
  color: white;
  text-align: center;
  padding: 2rem 5rem; }
  .invalid-invoice .title {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1rem; }
  .invalid-invoice .details {
    font-size: 2rem; }
  .invalid-invoice button {
    padding: 2rem 3rem;
    font-size: 2rem; }

.unsubscribe_layout {
  margin: 2rem; }
  .unsubscribe_layout p {
    margin-bottom: 2rem;
    font-size: 1.5rem; }

.variable_bill_notice {
  max-width: 600px;
  font-size: 1.5rem;
  padding: 1rem;
  height: 100%;
  width: 100%;
  overflow-y: scroll; }
  .variable_bill_notice a {
    display: block; }
  .variable_bill_notice p {
    margin: 1rem 0; }
  .variable_bill_notice .content {
    display: flex; }
  .variable_bill_notice .breakdown_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap; }
    .variable_bill_notice .breakdown_wrapper .breakdown {
      padding: 1rem; }
      .variable_bill_notice .breakdown_wrapper .breakdown .title {
        font-size: 1.3rem;
        font-weight: bolder;
        margin-bottom: 1rem;
        text-transform: uppercase;
        color: #383636; }
  .variable_bill_notice .action_wrapper {
    justify-content: center;
    margin-top: 2rem;
    flex-wrap: wrap;
    flex-direction: row; }
    .variable_bill_notice .action_wrapper button {
      padding: 1rem 3rem;
      margin: 1rem;
      text-transform: uppercase; }

.overview-row {
  padding: 2rem;
  justify-content: space-evenly; }
  .overview-row .card {
    flex-grow: 1; }

.link-text {
  font-style: italic;
  font-size: 1.2rem; }

.card {
  padding: 1rem;
  justify-content: space-between;
  flex-direction: column;
  display: flex;
  box-shadow: 0 0 5px 0 #ccc;
  border-radius: 0.5rem;
  margin: 1rem;
  position: relative;
  overflow: hidden; }
  .card .card__header {
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  .card .card__header-with-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.5rem;
    margin-bottom: 1rem; }
  .card .card__value {
    font-size: 2.5rem; }
  .card .icon {
    align-items: center;
    position: absolute;
    display: flex;
    /* z-index: -1; */
    bottom: 4px;
    right: 11px;
    opacity: 0.08;
    font-size: 5rem; }

.card.small {
  height: 10rem;
  min-width: 25rem; }

.card.big .card__value {
  font-size: 3.5rem; }

.code-layout {
  font-style: italic;
  padding: 2rem 1rem;
  align-items: center;
  display: flex;
  background: whitesmoke;
  color: #404040;
  font-size: 1.3rem;
  overflow-wrap: break-word;
  word-break: break-all;
  margin: 0 1rem; }

.paginator {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
  justify-content: center;
  flex-direction: row;
  margin: 2rem 0 0 0;
  padding: 1rem;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 0 1px 1px #dedede; }
  .paginator button {
    padding: 1rem;
    background: white;
    border-radius: 0.01rem; }
  .paginator button:disabled {
    color: white; }
  .paginator button:nth-child(1) {
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem; }
  .paginator button:nth-last-child(1) {
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem; }
  .paginator button:focus {
    outline: none; }
  .paginator button.current {
    background: #094063;
    color: #FFFFFA; }
  .paginator p {
    margin: 1rem;
    font-size: 2rem; }

.tooltip {
  font-size: 1.5rem;
  margin-left: 1rem;
  display: inline; }

[data-tooltip] {
  position: relative;
  z-index: 2;
  cursor: pointer; }

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  opacity: 0;
  pointer-events: none; }

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 160px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: rgba(51, 51, 51, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2; }

/* Position tooltip below element when position set to bottom */
[data-tooltip-position="bottom"]:hover:before {
  top: 100%;
  height: 100%;
  display: inline-table; }

/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 80%;
  left: 50%;
  margin-left: -5px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0; }

/* Triangle hack to make tooltip look like a speech bubble on position bottom */
[data-tooltip-position="bottom"]:after {
  bottom: 0;
  border: none;
  border-bottom: 5px solid #000;
  border-bottom: 5px solid rgba(51, 51, 51, 0.9);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent; }

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  opacity: 1; }

@media screen and (max-width: 768px) {
  .l-form .select {
    width: 100%; }
  .welcome-wrapper {
    padding: 2rem; }
    .welcome-wrapper h1 {
      font-size: 5rem; }
    .welcome-wrapper h4 {
      font-size: 1.8rem; }
    .welcome-wrapper ul.action-chooser {
      justify-content: center;
      overflow-y: scroll; }
      .welcome-wrapper ul.action-chooser .action-item {
        min-width: 120px; }
        .welcome-wrapper ul.action-chooser .action-item .action-item__icon {
          width: 80px;
          height: 80px; }
        .welcome-wrapper ul.action-chooser .action-item .action-item__label {
          margin-top: 1rem;
          font-size: 1rem; }
  .login_form {
    font-size: 1.2rem; }
  .form_logo img {
    height: 2.6rem; }
  .form_logo p {
    font-size: 1.5rem; }
  #divider {
    font-size: 3rem; }
  .active {
    display: none; }
  .sidebar {
    width: 20%;
    height: 100%;
    font-size: 1.6rem;
    min-width: 25rem;
    border-right: 1px solid rgba(9, 64, 99, 0.1); }
    .sidebar .sidebar__top {
      display: flex;
      padding-left: 2.5rem;
      align-items: center;
      border-bottom: 1px solid rgba(9, 64, 99, 0.1);
      flex-direction: column; }
      .sidebar .sidebar__top img {
        width: 75px;
        max-height: 75px;
        display: block;
        margin: 0 auto; }
    .sidebar ul {
      width: 100%; }
  .dashboard__table th {
    padding: 0.5rem 1rem;
    font-size: 1.2rem;
    text-transform: uppercase; }
  .dashboard__table td {
    padding: 0.5rem 1rem;
    font-size: 1.4rem;
    cursor: pointer; }
  .l_card-holder {
    justify-content: center; }
  .l_card {
    width: 100%;
    margin: 0.1rem; }
    .l_card .big_num {
      font-size: 1.8rem; }
  .profile__info {
    padding-top: 3rem; }
  .profile__planinfo {
    padding-top: 1rem; }
  .modal-content {
    width: 100%;
    height: 100%; }
  .spreadSheet {
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
    position: relative; }
  .addEmpForm {
    height: auto;
    width: 100%; }
  .l-addEmployee {
    flex-wrap: wrap; }
  #or {
    margin: auto;
    padding: 1rem; }
  .company-info .usage .usage-summary {
    width: 100%;
    justify-content: center; }
  .company-info .usage .usage-graph {
    width: 100%;
    justify-content: center; }
  .signup__header {
    padding-bottom: 2rem;
    padding-top: 5rem;
    flex-wrap: wrap; }
    .signup__header img {
      height: 2.5rem; }
    .signup__header p {
      font-size: 1.6rem; }
  .button-header {
    justify-content: center; }
  .checkout-header__plan-count {
    font-size: 1.5rem; }
  .checkout-header__button {
    margin-left: 1rem;
    padding: 1rem;
    font-size: 1rem; }
  .checkout-container {
    width: 100%; }
  .plan_container {
    flex-direction: column;
    min-height: 104rem;
    align-items: center; }
  .plan_item {
    width: 100%; }
  .plan-selection__duration-wrapper .plan_duration_btn {
    padding: 0;
    font-size: 1.4rem;
    height: 4rem;
    width: 10rem;
    cursor: pointer; }
  .plan-selection__duration-wrapper .plan_duration_btn_active {
    font-size: 1.6rem; }
  .plan-selection__duration-wrapper .plan_duration_copy {
    font-size: 1.8rem;
    line-height: 3rem; }
  .mdl-layout {
    width: 100%;
    font-size: 1.2rem;
    padding: 0; }
    .mdl-layout .mdl-list, .mdl-layout .mdl-details {
      display: none; }
    .mdl-layout .mdl-details {
      min-height: 100%; }
      .mdl-layout .mdl-details .mdl-details__header {
        padding: 1rem;
        font-size: 1.5rem; }
      .mdl-layout .mdl-details .mdl-details__content {
        padding: 0;
        font-size: 1rem;
        min-height: 280px; }
      .mdl-layout .mdl-details .mdl-details__footer {
        padding: 0;
        margin: 0; }
        .mdl-layout .mdl-details .mdl-details__footer .link_button {
          display: flex !important;
          font-size: 1rem; }
        .mdl-layout .mdl-details .mdl-details__footer .primary_button {
          font-size: 1rem;
          margin: 0; }
    .mdl-layout .view {
      display: flex;
      flex-direction: column;
      width: 100%; }
    .mdl-layout .active {
      display: block; }
  .no-enrollee-wrapper {
    flex-direction: column; }
    .no-enrollee-wrapper section {
      height: 100%; }
    .no-enrollee-wrapper section:nth-child(n+2) {
      border-left: none;
      border-top: 1px solid #eee; }
  .invalid-invoice .title {
    font-size: 2rem; }
  .invalid-invoice .details {
    font-size: 1.2rem; }
  .form-horizontal .col {
    width: 100%; }
  .variable_bill_notice .breakdown_wrapper .breakdown {
    padding: 1rem 0; }
  .variable_bill_notice .action_wrapper button {
    margin: 1rem 0; }
  .two-column-layout .col {
    flex: none;
    width: 100%; }
  .paginator button {
    padding: 0.5rem;
    border-radius: 0;
    margin: 0; }
  .paginator p {
    margin: 0.5rem;
    font-size: 1rem; } }
